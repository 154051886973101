import React from "react";
import logo from "../images/logo.png";
import {
  IconBrandFacebookFilled,
  IconBrandInstagram,
  IconBrandLinkedin,
  IconBrandTelegram,
} from "@tabler/icons-react";
import { useNavigate } from "react-router-dom";
import { ContextStore } from "../context/WebContext";
import hart from "../images/hart.png";

const Footer = () => {
  const navigate = useNavigate();
  const serviceOption = [
    { name: "home", link: "/" },
    { name: "about", link: "/about" },
    { name: "tour list", link: "/tour-list" },
    { name: "blog", link: "/blog" },
    { name: "contact us ", link: "/contact-us" },
  ];
  const contactDetails = [
    {
      name: "phone no",
      values: [
        { value: "Surat - +91 88667 71988", link: "tel:+918866771988" },
        { value: "Rajkot - +91 95124 01110", link: "tel:+919512401110" },
      ],
    },
    {
      name: "email",
      values: [
        {
          value: "mojkesaudagar@gmail.com",
          link: "mailto:mojkesaudagar@gmail.com",
        },
      ],
    },
    {
      name: "address",
      values: [
        {
          value: `1012, 10TH FLOOR, RAJHANS MONTESSA, NEAR AIRPORT DUMAS ROAD, BESIDE LE MERIDIAN HOTEL,SURAT, GUJARAT - 395007`,
          link: "https://maps.app.goo.gl/iKgko9z7nK3PvGwE6",
        },
        {
          value: `1102, North Block, Twin Star, Near Balaji Hall, 150 Feet Ring Road, Rajkot`,
          link: "https://maps.app.goo.gl/vKRSN8LdhHtdGQdj7",
        },
      ],
    },
  ];
  return (
    <div className="mt-10">
      <div className="flex flex-col md:flex-row justify-center items-center bg-[#FEF4E4] font-inter py-2 md:py-4 px-10">
        <div className="text-[16px] text-black font-medium flex items-center gap-1">
          <span>"Made With</span> <img src={hart} className="h-3 w-4" />{" "}
          <span>By Moj Ke Saudagar"</span>
        </div>
      </div>
      <footer className="bg-black text-white py-10">
        <div className="w-11/12 xl:w-4/5 mx-auto px-4 bg-[url(/public/footer-img.png)] bg-cover bg-bottom">
          <div className="flex flex-wrap justify-between space-y-10 lg:space-y-0 border-b-2 border-b-gray-500 pb-8">
            <div className="w-full flex flex-col items-center sm:w-1/2 lg:w-[20%] 2xl:w-[15%] mb-6 sm:mb-0 text-center sm:text-left">
              <img
                src={logo}
                alt="Logo"
                className="mx-auto sm:mx-0 mb-4 flex"
              />
              <div className="flex justify-start sm:justify-start space-x-4">
                <a
                  target="_blank"
                  href="https://www.instagram.com/mojkesaudagar/"
                  className="bg-gray-500/50 h-8 w-8 flex justify-center items-center rounded-full cursor-pointer"
                >
                  <IconBrandInstagram className="h-5 w-5 text-white" />
                </a>
                <a
                  target="_blank"
                  href="https://www.facebook.com/profile.php?id=61555145678088&mibextid=ZbWKwL"
                  className="bg-gray-500/50 h-8 w-8 flex justify-center items-center rounded-full cursor-pointer"
                >
                  <IconBrandFacebookFilled className="h-5 w-5 text-white" />
                </a>
                <a
                  target="_blank"
                  href="https://in.linkedin.com/in/keval-babariya-3a26091a4"
                  className="bg-gray-500/50 h-8 w-8 flex justify-center items-center rounded-full cursor-pointer"
                >
                  <IconBrandLinkedin className="h-5 w-5 text-white" />
                </a>
                <a
                  target="_blank"
                  href="https://t.me/mojkesaudagar"
                  className="bg-gray-500/50 h-8 w-8 flex justify-center items-center rounded-full cursor-pointer"
                >
                  <IconBrandTelegram className="h-5 w-5 text-white" />
                </a>
              </div>
            </div>
            <div className="w-full sm:w-1/2 lg:w-1/4 mb-6 sm:mb-0">
              <h2 className="text-lg font-bold mb-4">About Us</h2>
              <div className="text-gray-400 mb-4 text-justify">
                Moj Ke Saudagar specializes in crafting bespoke travel
                experiences, from Himalayan treks to cultural explorations in
                Dubai and spiritual journeys to Mangi Tungi.
              </div>
              <div className="text-gray-400 text-justify">
                Join us at Moj Ke Saudagar and embark on a journey where
                exploration meets excellence, ensuring your travel dreams become
                extraordinary realities.
              </div>
            </div>
            <div className="w-full sm:w-1/2 lg:w-[12%] 2xl:w-[8%] mb-6 sm:mb-0">
              <h2 className="text-lg font-bold mb-4">Quick Links</h2>
              <ul className="text-gray-400">
                {serviceOption.map((item, ind) => (
                  <li key={ind} className="mb-2 cursor-pointer">
                    <div
                      onClick={() => {
                        navigate(item.link);
                        window.scrollTo({ top: 0, behavior: "smooth" });
                      }}
                      className="hover:text-white capitalize cursor-pointer"
                    >
                      {item.name}
                    </div>
                  </li>
                ))}
              </ul>
            </div>
            <div className="w-full sm:w-1/2 lg:w-1/4">
              <h2 className="text-lg font-bold mb-4">Contacts</h2>
              <div className="flex flex-col gap-5">
                {contactDetails.map((item, ind) => (
                  <div key={ind}>
                    <p className="capitalize text-[#FD6469] text-sm xl:text-lg">
                      {item.name}
                    </p>
                    <div className="flex flex-col">
                      {item.values.map((item, ind) => (
                        <a
                          target="_blank"
                          className="text-sm xl:text-lg cursor-pointer text-white"
                          key={ind}
                          href={item.link}
                        >
                          {item.value}
                        </a>
                      ))}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>

          <div className="mt-8 flex flex-wrap justify-between items-center text-gray-400">
            <div className="w-full sm:w-auto mb-4 sm:mb-0 text-center sm:text-left">
              <a
                onClick={() => {
                  navigate("/privacy-and-policy");
                }}
                className="hover:text-white cursor-pointer"
              >
                Privacy & Policy
              </a>
              <span className="mx-2">|</span>
              <a
                onClick={() => {
                  navigate("/terms-and-condition");
                }}
                className="hover:text-white cursor-pointer"
              >
                Terms & Condition
              </a>
            </div>
            <div className="w-full sm:w-auto text-center sm:text-right">
              &copy; 2024 MojKeSaudagar. All Rights Reserved.
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default Footer;
